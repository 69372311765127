import React from "react";
import { TEvent, TImage } from "@/shared/model/types";
import { Collapse, Hero, TypeBadge } from "@/shared/ui";
import { ShareButton } from "@/shared/ui/share-button";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@/shared/assets/icons/arrow-right.svg?react";
import { formatDate, formatTime, isDateGreaterThanNow } from "@/shared/lib";

interface IProps {
  images: TImage[];
  eventData: TEvent;
  children?: React.ReactNode;
  AdditionalDetails?: JSX.Element;
}

export const EventDetails: React.FC<IProps> = ({
  images,
  eventData: eventData,
  children,
  AdditionalDetails,
}) => {
  return (
    <>
      {images && (
        <Hero
          images={images}
          TypeBadge={<TypeBadge>{eventData?.type}</TypeBadge>}
        >
          <div className="flex h-full flex-col">
            <div className="flex items-center justify-between">
              <h3 className="text-xl fb">{eventData?.title}</h3>
              <div className="flex gap-2 ">
                <ShareButton size="sm" />
              </div>
            </div>
            <pre className="flex-grow overflow-y-auto basis-10 mt-2 hidden lg:block">
              {eventData?.description}
            </pre>
          </div>
        </Hero>
      )}
      <div className="block lg:hidden">
        {eventData?.description.length > 300 ? (
          <Collapse>
            <pre>{eventData?.description}</pre>
          </Collapse>
        ) : (
          <pre>{eventData?.description}</pre>
        )}
      </div>

      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-col w-full gap-2 mt-4 lg:mt-6 lg:w-1/2 ">
          <div className="p-4 bg-base-200 rounded-xl">
            <h4 className="text-xl fb">Информация о мероприятии</h4>
            <ul className="mt-2 space-y-2 list-disc ml-7">
              {eventData?.place && (
                <>
                  <li>
                    Место проведения:{" "}
                    <Link
                      to={"/places/" + eventData?.place?.id}
                      className="inline-flex link"
                    >
                      {eventData?.place?.title}
                      <ArrowRightIcon className="w-4" />
                    </Link>
                  </li>
                  <li>Адрес: {eventData?.place?.address}</li>
                </>
              )}
              {AdditionalDetails}
            </ul>
          </div>
          <div className="p-4 bg-base-200 rounded-xl">
            <h4 className="text-xl fb">Расписание</h4>
            <ul className="mt-2 space-y-2 list-disc ml-7">
              {eventData?.schedules.map((schedule) => (
                <li
                  key={schedule.id}
                  className={
                    isDateGreaterThanNow(schedule.date + "T" + schedule.time)
                      ? ""
                      : "line-through"
                  }
                >
                  {`${formatDate(schedule.date)}, ${formatTime(schedule.time)}${
                    schedule.duration
                      ? "-" + formatTime(schedule.time, schedule.duration)
                      : ""
                  }, Цена: ${
                    schedule.price === 0 ? "бесплатно" : schedule.price + "руб"
                  }`}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col w-full gap-2 lg:mt-6 lg:w-1/2 ">
          {children}
        </div>
      </div>
    </>
  );
};
