import { Link } from "react-router-dom";
import { Container } from ".";
import { TResImage } from "../model/types";

type TItem = {
  id: number;
  title: string;
  preview_image: TResImage;
};

interface IProps {
  items: TItem[];
  redirectUrl: string;
  isAddIdToRoute?: boolean;
}
export const HorizontalList: React.FC<IProps> = ({
  items,
  redirectUrl,
  isAddIdToRoute = true,
}) => {
  return (
    <>
      <Container direction="row" className="mt-2 text-xl fb">
        <div className="hidden w-full gap-2 lg:flex">
          {items &&
            items.map((item: TItem) => (
              <Link
                to={redirectUrl + (isAddIdToRoute ? item.id.toString() : "")}
                key={item.id}
                className="w-1/4 aspect-[1/1.2] overflow-hidden rounded-xl p-4 relative "
              >
                <img
                  className="absolute top-0 left-0 object-cover w-full h-full -z-10 "
                  src={
                    item.preview_image.formats.small?.url ||
                    item.preview_image.formats.thumbnail.url
                  }
                  alt="bg"
                />
                <div className="absolute top-0 left-0 w-full h-full -z-10 bottom-to-top-gradient-1"></div>
                <p className="absolute text-2xl text-white bottom-4 left-4">
                  {item.title}
                </p>
              </Link>
            ))}
        </div>

        <div className="flex w-full space-x-4 carousel carousel-center rounded-box lg:hidden">
          {items &&
            items.map((item: TItem) => (
              <Link
                to={redirectUrl + (isAddIdToRoute ? item.id.toString() : "")}
                key={item.id}
                className="relative w-[80%] md:w-[50%] carousel-item p-4 aspect-[1/1.2]"
              >
                <div className="absolute top-0 left-0 w-full h-full rounded-2xl bottom-to-top-gradient-1"></div>
                <img
                  className="absolute top-0 left-0 object-cover w-full h-full -z-10 rounded-2xl"
                  src={
                    item.preview_image.formats.small?.url ||
                    item.preview_image.formats.thumbnail.url
                  }
                  alt="bg"
                />
                <p className="absolute text-2xl text-white bottom-4 left-4">
                  {item.title}
                </p>
              </Link>
            ))}
        </div>
      </Container>
    </>
  );
};
