import { useCreateApplicationTourMutation } from "@/entities/tours";

type TPropsCreateApplicationExcursion = {
  name: string;
  tour_id: number;
  phone: string;
  email: string;
  total_price: number;
  customers_count: number;
  schedule: { price: number; date: string; time: string };
};

export const useJoinTour = () => {
  const [
    createApplicationExcursionQuery,
    { isSuccess: isSuccessCreatedApplication },
  ] = useCreateApplicationTourMutation();

  async function createApplicationTour(data: TPropsCreateApplicationExcursion) {
    try {
      await createApplicationExcursionQuery({ data }).unwrap();
    } catch (error) {
      console.log(error);
    }
  }

  return {
    createApplicationTour,
    isSuccessCreatedApplication,
  };
};
