import {
  useJoinFreeEventMutation,
  usePayEventMutation,
} from "@/entities/events";
import { selectUserId } from "@/entities/user";
import { useAppSelector } from "@/shared/model/hooks";
import { useEffect, useState } from "react";

type TJoinEventProps = {
  personsCount: number;
  scheduleId: number;
  eventId: number;
};

export const useJoinEvent = () => {
  const [joinFreeEventQuery, { isLoading: joinFreeEventQueryStatus }] =
    useJoinFreeEventMutation();
  const [payEventQuery, { isLoading: payEventQueryLoadingStatus }] =
    usePayEventMutation();
  const userId = useAppSelector(selectUserId);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (joinFreeEventQueryStatus || payEventQueryLoadingStatus) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [joinFreeEventQueryStatus, payEventQueryLoadingStatus]);

  async function joinFreeEvent({
    personsCount,
    scheduleId,
    eventId,
  }: TJoinEventProps) {
    if (scheduleId && userId && eventId && personsCount) {
      await joinFreeEventQuery({
        users_permissions_user: userId,
        event: Number(eventId),
        schedule_id: scheduleId,
        persons_count: personsCount,
      });
    }
  }

  async function payEvent({
    personsCount,
    scheduleId,
    eventId,
  }: TJoinEventProps) {
    if (scheduleId && userId && eventId && personsCount) {
      const redirectUrl = window.location.hostname + "/profile/tickets";
      await payEventQuery({
        user_id: userId,
        seats_ids: [],
        event_id: eventId,
        schedule_id: scheduleId,
        persons_count: personsCount,
        payment_payload: {
          payment_method_type: "bank_card",
          return_url: redirectUrl,
        },
      });
    }
  }
  return {
    payEvent,
    joinFreeEvent,
    isFetching,
  };
};
