import { Link } from "react-router-dom";
import { Container, Title } from "@/shared/ui";
import tourBackground from "@/shared/assets/tours.png";
import entertainmentsBackground from "@/shared/assets/entertainments.png";
import excursionsBackground from "@/shared/assets/excursions.png";
import gastronomyBackground from "@/shared/assets/gastronomy.png";
import hotelsBackground from "@/shared/assets/hotels.png";
import placesBackground from "@/shared/assets/places.png";

export const Plates: React.FC = () => {
  return (
    <div id="plates">
      <Container
        direction="col"
        className="mt-[4rem] lg:mt-[7rem] fb uppercase"
      >
        <Title>Чем заняться</Title>
        <div className="grid grid-cols-8 gap-4 text-base sm:text-xl lg:grid-cols-12 lg:text-2xl">
          <Link
            to="/tours"
            className="col-span-4 lg:col-span-6 p-2 sm:p-4 rounded-lg aspect-square sm:h-[22rem] sm:aspect-auto relative overflow-hidden"
          >
            <img
              className="absolute top-0 left-0 object-cover w-full h-full -z-20"
              src={tourBackground}
              alt="bg"
            />
            <div className="absolute top-0 left-0 w-full h-full -z-10 top-to-bottom-gradient"></div>
            <p className="text-white ">Туры в Элисту</p>
          </Link>
          <Link
            to="/places/list/oteli"
            className="relative col-span-4 p-2 sm:p-4 overflow-hidden rounded-lg lg:col-span-3"
          >
            <img
              className="absolute top-0 left-0 object-cover w-full h-full -z-20 "
              src={hotelsBackground}
              alt="bg"
            />
            <div className="absolute top-0 left-0 w-full h-full -z-10 top-to-bottom-gradient"></div>
            <p className="text-white ">Отели</p>
          </Link>
          <Link
            to="/excursions"
            className="relative col-span-4 p-2 sm:p-4 overflow-hidden rounded-lg lg:col-span-3"
          >
            <img
              className="absolute top-0 left-0 object-cover w-full h-full -z-20 "
              src={excursionsBackground}
              alt="bg"
            />
            <div className="absolute top-0 left-0 w-full h-full -z-10 top-to-bottom-gradient"></div>
            <p className="text-white ">Экскурсии</p>
          </Link>
          <Link
            to="/places/list/rajony-kalmykii"
            className="col-span-4 p-2 sm:p-4 rounded-lg aspect-square sm:h-[22rem] sm:aspect-auto overflow-hidden relative"
          >
            <img
              className="absolute top-0 left-0 object-cover w-full h-full -z-20 "
              src={placesBackground}
              alt="bg"
            />
            <div className="absolute top-0 left-0 w-full h-full -z-10 top-to-bottom-gradient"></div>
            <p className="text-white ">Интересные места</p>
          </Link>
          <Link
            to="/places/list/sport-i-razvlechenie"
            className="col-span-4 p-2 sm:p-4 rounded-lg aspect-square sm:h-[22rem] sm:aspect-auto overflow-hidden relative"
          >
            <img
              className="absolute top-0 left-0 object-cover w-full h-full -z-20 "
              src={entertainmentsBackground}
              alt="bg"
            />
            <div className="absolute top-0 left-0 w-full h-full -z-10 top-to-bottom-gradient"></div>
            <p className="text-white ">Спорт и развлечения</p>
          </Link>
          <Link
            to="/places/list/gastronomiya"
            className="relative col-span-4 p-2 sm:p-4 overflow-hidden rounded-lg"
          >
            <img
              className="absolute top-0 left-0 object-cover w-full h-full -z-20 "
              src={gastronomyBackground}
              alt="bg"
            />
            <div className="absolute top-0 left-0 w-full h-full -z-10 top-to-bottom-gradient"></div>
            <p className="text-white ">Где вкусно поесть</p>
          </Link>
        </div>
      </Container>
    </div>
  );
};
