import { HomeSlider } from "@/entities/home-slider";
import { Background } from "@/shared/ui";
import { Plates } from "./plates";
import { ArticlesHome } from "@/widgets/articles";
import { ReadyRoutesHorizontal } from "@/entities/ready-routes";

export const Home: React.FC = () => {
  return (
    <div className="neutralize-main-padding-top-content">
      <Background />
      <HomeSlider />
      <Plates />
      <ReadyRoutesHorizontal className="mt-20" />
      <ArticlesHome className="mt-20" />
    </div>
  );
};
