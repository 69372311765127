import { TResImage } from "@/shared/model/types";
import { Card, ListGrid } from "@/shared/ui";

type TExcursion = {
  title: string;
  rating: number;
  id: number;
  preview_image: TResImage;
};

interface IProps {
  excursion: TExcursion[];
}

export const ExcursionsGrid: React.FC<IProps> = ({ excursion }) => {
  return (
    <div className="flex-1">
      <ListGrid>
        {excursion?.map((excursion) => (
          <Card
            key={excursion.id}
            preview_image_url={
              excursion.preview_image.formats.small?.url ||
              excursion.preview_image.formats.thumbnail.url
            }
            redirectLink={"/excursions/" + excursion.id}
          >
            <h3 className="fb">{excursion.title}</h3>
          </Card>
        ))}
      </ListGrid>
    </div>
  );
};
