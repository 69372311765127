import { sessionApi } from "@/entities/session";
import { createAsyncThunk } from "@reduxjs/toolkit";

type Params = {
  phone: string;
  password: string;
};

export const loginThunk = createAsyncThunk<
  void | Error,
  Params,
  { state: RootState }
>("auth/login", async (body: Params, { dispatch }) => {
  try {
    await dispatch(sessionApi.endpoints.login.initiate(body)).unwrap();
  } catch (error: unknown) {
    if (error && typeof error === "string") throw new Error(error);
    throw new Error("Непредвиденная ошибка");
  }
});
