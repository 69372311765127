import { useParams } from "react-router-dom";
import { TImage, TResImage } from "@/shared/model/types";
import { BreadCrumbs, Loading } from "@/shared/ui";
import {
  ExcursionDetails,
  useGetExcursionByIdQuery,
} from "@/entities/excursion";
import { JoinExcursion } from "@/features/join-excursion";
import { filterSchedules, findMinByField } from "@/shared/lib";
import { useMemo } from "react";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const ExcursionInfo: React.FC = () => {
  const { id } = useParams();
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { excursionData, isLoading, images, isFetching } =
    useGetExcursionByIdQuery(
      { id: Number(id) },
      {
        selectFromResult: (res) => ({
          excursionData: res?.data?.data,
          images: res?.data?.data?.images?.map(
            (image: TResImage): TImage => ({
              id: image?.id,
              url:
                image?.formats?.medium?.url ||
                image?.formats?.small?.url ||
                image?.formats?.thumbnail?.url,
            })
          ),
          ...res,
        }),
        skip: !id,
      }
    );

  const minPrice = useMemo(() => {
    if (excursionData && excursionData.schedules) {
      const filteredSchedules = filterSchedules(excursionData.schedules);
      return findMinByField(filteredSchedules, "price");
    }
  }, [excursionData]);

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div>
      {!isOpenFromMobileApp && <BreadCrumbs />}
      {excursionData && (
        <ExcursionDetails
          images={images}
          excursionData={excursionData}
          AdditionalDetails={
            minPrice && (
              <li>
                Стоимость:{" "}
                {minPrice === 0
                  ? "есть бесплатные сеансы"
                  : "от " + minPrice + " руб."}
              </li>
            )
          }
        >
          {minPrice ? (
            <JoinExcursion
              excursionData={excursionData}
              btnText={
                minPrice === 0
                  ? "Зарегистрироваться"
                  : "Купить экскурсию от " + minPrice + " руб"
              }
            />
          ) : (
            <button className="w-full btn text-lg font-[400]" disabled>
              Запись пока не доступна
            </button>
          )}
        </ExcursionDetails>
      )}
    </div>
  );
};
