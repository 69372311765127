import React, { useState } from "react";
import InputMask from "react-input-mask-format";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "@/shared/model/hooks";
import { clearPhone } from "@/shared/lib";

import { loginThunk } from "../model/login-thunk";

interface IFormValues {
  phone: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<IFormValues>();

  async function onSubmit({ phone, password }: IFormValues) {
    if (phone && password) {
      const cleanedPhone: string = clearPhone(phone);
      try {
        await dispatch(loginThunk({ phone: cleanedPhone, password })).unwrap();
      } catch (error: unknown) {
        if (error && typeof error === "object" && "message" in error)
          setError(error.message as string);
      }
    }
  }

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      {errors.phone && (
        <label className="text-error">{errors.phone.message}</label>
      )}

      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{
          required: "Введите номер телефона",
          validate: (value) => {
            if (value.includes("_")) return "Введите номер телефона";
          },
        }}
        render={({ field }) => (
          <InputMask
            {...field}
            className={`input input-bordered ${
              errors.phone ? "input-error" : ""
            }`}
            mask="+7 (999) 999-99-99"
            placeholder="Телефон"
          />
        )}
      />

      {errors.password && (
        <label className="text-error">{errors.password.message}</label>
      )}

      <input
        type="password"
        placeholder="Пароль"
        className={`input input-bordered ${
          errors.password ? "input-error" : ""
        }`}
        {...register("password", {
          required: "Введите пароль",
          validate: (value) => {
            if (value.length < 6) return "Минимум 6 символов";
          },
        })}
      />

      {error && <label className="text-error">{error}</label>}

      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : (
          "Войти"
        )}
      </button>
    </form>
  );
};
