import { useEffect, useState } from "react";
import {
  PlacesGrid,
  useGetAllPlacesQuery,
  useGetPlaceCategoryBySlugQuery,
} from "@/entities/places";
import { useWindowDimensions } from "@/shared/hooks";
import { Pagination } from "@/features/pagination";
import { Loading, Title } from "@/shared/ui";
import { useParams } from "react-router-dom";
import {
  FilterByTag,
  FilterByTagDropdownMenu,
  FilterByWorkingTime,
} from "@/features/filter-places";
import { FavoriteButton } from "@/features/favorite-place";
import { useLocation } from "react-router-dom";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const PlacesList: React.FC = () => {
  const { category } = useParams();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filterByWorkingTime, setFilterByWorkingTime] = useState("");
  const [filterByTags, setFilterByTags] = useState<string>("");
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);
  const location = useLocation();

  function handleChangeFilterByTags(filter: string) {
    setFilterByTags(filter);
  }

  useEffect(() => {
    setPageNumber(1);
  }, [location]);

  const { totalPages, placesData, isLoading, isFetching } =
    useGetAllPlacesQuery(
      {
        populate:
          "populate=preview_image, working_hours, working_hours_weekends, type",
        page: pageNumber,
        pageSize: 12,
        filter: `filters[$and][0][category][slug][$eq]=${category}&${filterByWorkingTime}&${filterByTags}`,
      },
      {
        selectFromResult: (res) => ({
          totalPages: res?.data?.meta?.pagination?.pageCount,
          placesData: res?.data?.data,
          ...res,
        }),
        skip: !category,
      }
    );

  const { tags, categoryTitle } = useGetPlaceCategoryBySlugQuery(
    {
      slug: category!,
    },
    {
      selectFromResult: (res) => {
        return {
          tags: res?.data?.data?.place_tags,
          categoryTitle: res?.data?.data?.title,
        };
      },
      skip: !category,
    }
  );

  return (
    <div className="flex flex-col flex-grow">
      {!isOpenFromMobileApp && category && <Title>{categoryTitle}</Title>}
      <div className="flex flex-col gap-4 ">
        <FilterByWorkingTime setFilterByWorkingTime={setFilterByWorkingTime} />
        {isDesktop ? (
          <FilterByTag tags={tags} setFilterByTags={handleChangeFilterByTags} />
        ) : (
          <FilterByTagDropdownMenu
            tags={tags}
            setFilterByTags={handleChangeFilterByTags}
            width="full"
          />
        )}
      </div>
      <div className="flex-1 mt-4">
        {isFetching ||
          (isLoading && (
            <div className="flex items-center justify-center w-full h-full">
              <Loading size="lg" />
            </div>
          ))}
        {placesData && (
          <PlacesGrid FavoriteButton={FavoriteButton} places={placesData} />
        )}
      </div>

      {totalPages > 0 && (
        <Pagination
          totalPages={totalPages}
          page={pageNumber}
          setPage={setPageNumber}
        />
      )}
    </div>
  );
};
