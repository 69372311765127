import { useAppSelector } from "@/shared/model/hooks";
import { selectUserId } from "..";
import {
  useGetTripPlacesQuery,
  useUpdateTripPlacesMutation,
} from "../api/user-api";
import { TPlace } from "@/shared/model/types";

export const useTripPlaces = () => {
  const [updateTripPlaces] = useUpdateTripPlacesMutation();
  const userId = useAppSelector(selectUserId);

  const { tripPlaces, isLoading, error } = useGetTripPlacesQuery("", {
    selectFromResult(res) {
      return {
        tripPlaces: res?.data?.trip_places,
        ...res,
      };
    },
    skip: !userId,
  });

  async function setTripPlaces(newPlaceIds: number[]) {
    if (tripPlaces && userId) {
      const isEqualPlaceIds =
        JSON.stringify(tripPlaces) === JSON.stringify(newPlaceIds);

      if (!isEqualPlaceIds) {
        await updateTripPlaces({ id: userId, trip_places: newPlaceIds });
      }
    }
  }

  async function toggleTripPlace(placeId: number) {
    if (userId) {
      const prevPlacesIds = tripPlaces?.map((place: TPlace) => place.id);
      const newPlacesIds = prevPlacesIds.includes(placeId)
        ? prevPlacesIds?.filter((id: number) => id !== placeId)
        : [...prevPlacesIds, placeId];
      await updateTripPlaces({ id: userId, trip_places: newPlacesIds });
    }
  }

  function checkingIsTripPlace(placeId: number): boolean {
    if (tripPlaces && tripPlaces.some((place: TPlace) => place.id === placeId))
      return true;
    return false;
  }

  return {
    checkingIsTripPlace,
    toggleTripPlace,
    setTripPlaces,
    tripPlaces,
    isLoading,
    error,
  };
};
