import { TResImage } from "@/shared/model/types";
import { Card, ListGrid } from "@/shared/ui";

type TArticle = {
  title: string;
  id: number;
  preview_image: TResImage;
  view_counter: number;
  sub_title: string;
};

interface IProps {
  articles: TArticle[];
}

export const ArticlesGrid: React.FC<IProps> = ({ articles }) => {
  return (
    <div className="flex-1">
      <ListGrid>
        {articles?.map((article) => {
          return (
            <Card
              key={article.id}
              preview_image_url={
                article.preview_image.formats.small?.url ||
                article.preview_image.formats.thumbnail.url
              }
              redirectLink={"/articles/" + article.id}
            >
              <h3 className="fb">{article.title}</h3>
              <p className="w-full truncate">{article?.sub_title}</p>
            </Card>
          );
        })}
      </ListGrid>
    </div>
  );
};
