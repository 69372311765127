import { useGetAllEventsQuery } from "@/entities/events";
import { getCurrentDateISO, getNext120Days } from "@/shared/lib";
import { TEvent, TSchedule } from "@/shared/model/types";
import { HorizontalScroll } from "@/shared/ui";
import { DateTime } from "luxon";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";

interface IProps {
  className?: string;
  setFilterByDate: (value: string) => void;
}

const WEEK_DAYS = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
const MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const FilterByDate: React.FC<IProps> = ({
  className = "",
  setFilterByDate,
}) => {
  const currentDate = getCurrentDateISO();
  const [filteredEventsIds, setFilteredEventsIds] = useState<number[]>();
  const [selectedDay, setSelectedDay] = useState<DateTime>();

  const { eventsData } = useGetAllEventsQuery(
    {
      filter: "filters[$and][0][date_delition][$gt]=" + currentDate,
      populate: "populate=schedules.date&fields[0]=id",
      pageSize: 9999,
    },
    {
      selectFromResult: (res) => ({
        eventsData: res?.data?.data,
      }),
    }
  );

  function selectDateHandler(day: DateTime) {
    if (day?.toSeconds() === selectedDay?.toSeconds()) {
      setFilteredEventsIds(undefined);
      setSelectedDay(undefined);
    } else if (eventsData && day) {
      const filteredEventsIds = eventsData
        .filter((event: TEvent) =>
          event.schedules?.some(
            (schedule: TSchedule) => schedule.date === day.toISODate()
          )
        )
        .map((event: TEvent) => event.id);

      setFilteredEventsIds(filteredEventsIds);
      setSelectedDay(day);
    }
  }

  useEffect(() => {
    if (filteredEventsIds) {
      const query = qs.stringify(
        {
          filters: {
            id: {
              $in: filteredEventsIds.length === 0 ? -1 : filteredEventsIds,
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      setFilterByDate(query);
    } else setFilterByDate("");
  }, [filteredEventsIds]);

  const dates = useMemo(() => getNext120Days(), []);

  return (
    <HorizontalScroll>
      <div className={className + " flex gap-8 mt-6"}>
        {Object.keys(dates).map((mountIndex) => (
          <ul key={mountIndex} className="relative flex gap-2 ">
            <span className="absolute left-0 ml-2 text-gray-500 bottom-full">
              {MONTHS[Number(mountIndex) - 1]}
            </span>
            {dates[Number(mountIndex)].days.map((day) => (
              <li
                key={day.toSeconds()}
                onClick={() => {
                  selectDateHandler(day);
                }}
                className={`flex flex-col items-center gap-1 py-1 cursor-pointer rounded-2xl select-none w-[2.5rem] ${
                  selectedDay?.toSeconds() === day.toSeconds()
                    ? "bg-primary"
                    : "hover:bg-base-200"
                }`}
              >
                <span
                  className={`text-2xl fb ${
                    day.weekday > 5 ? "text-error" : "text-gray-500"
                  }`}
                >
                  {day.day}
                </span>
                <span
                  className={`text-xs ${
                    day.weekday > 5 ? "text-error" : "text-gray-500"
                  }`}
                >
                  {WEEK_DAYS[day.weekday - 1]}
                </span>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </HorizontalScroll>
  );
};
