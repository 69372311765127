import { Container, Title } from "@/shared/ui";

import { Link } from "react-router-dom";
import { HorizontalList } from "@/shared/ui";
import { useReadyRoutes } from "..";

interface IProps {
  className?: string;
}

export const ReadyRoutesHorizontal: React.FC<IProps> = ({ className }) => {
  const { readyRoutes } = useReadyRoutes();
  if (!readyRoutes || readyRoutes?.length === 0) return <></>;

  return (
    <div className={className}>
      <Title>маршруты</Title>
      <HorizontalList
        redirectUrl={"/my-guide/"}
        isAddIdToRoute={false}
        items={readyRoutes}
      />
      <Container direction="row" className="justify-center mt-4">
        <Link
          to={"/my-guide/"}
          className="w-full text-base text-center uppercase md:mr-2 md:w-auto btn btn-primary"
        >
          Создать свой маршрут
        </Link>
      </Container>
    </div>
  );
};
