import { useParams } from "react-router-dom";
import { TImage, TResImage } from "@/shared/model/types";
import { BreadCrumbs, Loading } from "@/shared/ui";
import { TourDetails, useGetTourByIdQuery } from "@/entities/tours";
import { useMemo } from "react";
import { filterSchedules, findMinByField } from "@/shared/lib";
import { JoinTour } from "@/features/join-tour";
import { selectIsOpenFromMobileApp } from "@/entities/session";
import { useAppSelector } from "@/shared/model/hooks";

export const TourInfo: React.FC = () => {
  const { id } = useParams();
  const isOpenFromMobileApp = useAppSelector(selectIsOpenFromMobileApp);

  const { tourData, isLoading, images, isFetching } = useGetTourByIdQuery(
    { id: Number(id) },
    {
      selectFromResult: (res) => ({
        tourData: res?.data?.data,
        images: res?.data?.data?.images?.map(
          (image: TResImage): TImage => ({
            id: image?.id,
            url:
              image?.formats?.medium?.url ||
              image?.formats?.small?.url ||
              image?.formats?.thumbnail?.url,
          })
        ),
        ...res,
      }),
      skip: !id,
    }
  );

  const minPrice = useMemo(() => {
    if (tourData && tourData.schedules) {
      const filteredSchedules = filterSchedules(tourData.schedules);
      return findMinByField(filteredSchedules, "price");
    }
  }, [tourData]);

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div>
      {!isOpenFromMobileApp && <BreadCrumbs />}
      {tourData && (
        <TourDetails
          images={images}
          tourData={tourData}
          AdditionalDetails={
            minPrice && (
              <li>
                Стоимость:{" "}
                {minPrice === 0
                  ? "есть бесплатные сеансы"
                  : "от " + minPrice + " руб."}
              </li>
            )
          }
        >
          {minPrice ? (
            <JoinTour
              tourData={tourData}
              btnText={
                minPrice === 0
                  ? "Зарегистрироваться"
                  : "Купить экскурсию от " + minPrice + " руб"
              }
            />
          ) : (
            <button className="w-full btn text-lg font-[400]" disabled>
              Запись пока не доступна
            </button>
          )}
        </TourDetails>
      )}
    </div>
  );
};
