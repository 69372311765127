import { Modal, Title } from "@/shared/ui";
import { LoginForm } from "./login-form";
import { Link } from "react-router-dom";

interface IProps {
  children: React.ReactNode;
  subtitle?: string;
}
export const ModalLogin: React.FC<IProps> = ({ children, subtitle }) => {
  return (
    <Modal
      content={
        <>
          <Title>вход в личный кабинет</Title>
          {subtitle && <p className="pb-4">{subtitle}</p>}
          <LoginForm />
          <div className="flex justify-between mt-2">
            <Link to={"/auth/forget-password"} className="link">
              Забыли пароль?
            </Link>
            <Link to={"/auth/registration"} className="link">
              Зарегистрироваться
            </Link>
          </div>
        </>
      }
    >
      {children}
    </Modal>
  );
};
