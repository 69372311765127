import { TEvent, TSchedule } from "@/shared/model/types";
import {
  Counter,
  Loading,
  Modal,
  Price,
  SelectSchedule,
  Title,
} from "@/shared/ui";
import { useState } from "react";
import { useJoinEvent } from "../hooks/use-join-event";
import { ModalWithHall } from "./modal-with-hall";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsAuthorized } from "@/entities/session";
import { Link } from "react-router-dom";
import { LoginForm } from "@/features/auth";

interface IProps {
  eventData: TEvent;
  btnText?: string;
}

const joinEventButtonCSSClasses = "text-lg btn btn-primary font-[400] w-full";

export const JoinEvent: React.FC<IProps> = ({
  eventData,
  btnText = "Записаться на мероприятие",
}) => {
  const isAuth = useAppSelector(selectIsAuthorized);

  return (
    <Modal
      className={isAuth ? "max-w-3xl" : ""}
      content={
        isAuth ? (
          eventData.hall ? (
            <ModalContentWithHall eventData={eventData} />
          ) : (
            <ModalContentWithoutHall eventData={eventData} />
          )
        ) : (
          <>
            <Title>вход в личный кабинет</Title>
            <LoginForm />
            <div className="flex justify-between mt-2">
              <Link to={"/auth/forget-password"} className="link">
                Забыли пароль?
              </Link>
              <Link to={"/auth/registration"} className="link">
                Зарегистрироваться
              </Link>
            </div>
          </>
        )
      }
    >
      <button className={joinEventButtonCSSClasses}>{btnText}</button>
    </Modal>
  );
};

const ModalContentWithoutHall: React.FC<IProps> = ({ eventData }) => {
  const [customerCount, setCustomerCount] = useState<number>(1);
  const [selectedSchedule, setSelectedSchedule] = useState<TSchedule>();
  const { payEvent, joinFreeEvent, isFetching } = useJoinEvent();

  async function joinEvent() {
    if (selectedSchedule) {
      if (selectedSchedule?.price) {
        payEvent({
          personsCount: customerCount,
          scheduleId: selectedSchedule.id,
          eventId: eventData.id,
        });
      } else {
        joinFreeEvent({
          personsCount: customerCount,
          scheduleId: selectedSchedule.id,
          eventId: eventData.id,
        });
      }
    }
  }
  return (
    <>
      <SelectSchedule
        onChangeSchedule={setSelectedSchedule}
        schedulesData={eventData.schedules}
      >
        <label className="label">
          <span className="label-text">
            Количество билетов {"(максимум 5)"}
          </span>
        </label>
        <Counter value={customerCount} onChangeCount={setCustomerCount} />
      </SelectSchedule>
      <button
        className={joinEventButtonCSSClasses + " mt-4"}
        disabled={!selectedSchedule || isFetching}
        onClick={joinEvent}
      >
        {isFetching ? (
          <Loading size="sm" />
        ) : selectedSchedule ? (
          selectedSchedule.price ? (
            <>
              Оплатить{" "}
              {<Price price={selectedSchedule.price * customerCount} />}
            </>
          ) : (
            "Записаться"
          )
        ) : (
          "Выберите дату и время сеанса"
        )}
      </button>
    </>
  );
};

const ModalContentWithHall: React.FC<IProps> = ({ eventData }) => {
  const [selectedSchedule, setSelectedSchedule] = useState<TSchedule>();

  return (
    <>
      <SelectSchedule
        onChangeSchedule={setSelectedSchedule}
        schedulesData={eventData.schedules}
      />

      {selectedSchedule ? (
        <ModalWithHall
          duration={selectedSchedule.duration}
          id={selectedSchedule.id}
          price={selectedSchedule.price}
          time={selectedSchedule.time}
          date={selectedSchedule.date}
          hall={eventData.hall}
          tickets={eventData?.tickets}
        >
          <button className={joinEventButtonCSSClasses + " mt-4"}>
            Выбрать места в зале
          </button>
        </ModalWithHall>
      ) : (
        <button className={joinEventButtonCSSClasses + " mt-4"} disabled>
          Выберите дату и время сеанса
        </button>
      )}
    </>
  );
};
