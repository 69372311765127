import SlickSlider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetSlidesQuery } from "../api/home-slider-api";
import { ISlide } from "../model/types";
import { Loading } from "@/shared/ui/loading";
import { Link } from "react-router-dom";
import ArrowRight from "@/shared/assets/icons/arrow-right.svg?react";

type TCustomArrow = {
  onClick?: () => void;
};

const CustomNextArrow: React.FC<TCustomArrow> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="cursor-pointer bg-gray-200 top-[50%] absolute right-4 rounded-full -translate-y-[50%] hidden md:block"
    >
      <ArrowRight className="w-7 sm:w-10" />
    </button>
  );
};
const CustomPrevArrow: React.FC<TCustomArrow> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="z-20 cursor-pointer bg-gray-200 top-[50%] absolute left-4 rounded-full -translate-y-[50%] hidden md:block"
    >
      <ArrowRight className="rotate-180 w-7 sm:w-10" />
    </button>
  );
};

const settings: Settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  fade: true,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

export const HomeSlider: React.FC = () => {
  const { data: slides, isLoading } = useGetSlidesQuery("");

  return (
    <SlickSlider {...settings} className="relative">
      {isLoading && (
        <div className="h-[90vh] lg:h-[100vh] flex justify-center items-center">
          <Loading size="lg" />
        </div>
      )}
      {!isLoading &&
        slides?.map(
          ({ id, title, description, button, backgroundImage }: ISlide) => (
            <div key={id} className="w-full h-[90vh] lg:h-[100vh]">
              <img
                src={backgroundImage.url}
                alt="background"
                className="absolute top-0 left-0 object-cover w-full h-full -z-10 "
              />
              <div className="absolute top-0 left-0 z-10 w-full h-full bottom-to-top-gradient-2 md:block"></div>
              <div className="max-w-[1300px] flex flex-col items-start justify-end h-full px-4 md:px-14  mx-auto box-content ">
                <h2
                  className={`uppercase text-primary z-20 kalmyk-font text-2xl md:text-4xl mb-2 md:mb-4`}
                >
                  {title}
                </h2>
                <div className="flex w-full lg:items-start mb-[10vh] lg:mb-[15vh] justify-between gap-4 flex-col lg:flex-row">
                  {description && (
                    <p className=" font-[600] text-white z-20 text-xl md:text-3xl ">
                      {description}
                    </p>
                  )}
                  {button && (
                    <Link to={button.url} className="z-20 ">
                      <button className="w-full h-auto text-xl font-light uppercase btn btn-primary md:text-nowrap">
                        {button.title}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )
        )}
    </SlickSlider>
  );
};
