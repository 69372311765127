import { ConfirmPhoneForm } from "@/features/auth";
import { Background, Title } from "@/shared/ui";
import { ErrorBoundary } from "@/widgets/layouts";
import { useParams } from "react-router-dom";

export const ConfirmPhone: React.FC = () => {
  const { action } = useParams<{ action: "registration" | "reset-password" }>();

  return (
    <ErrorBoundary>
      <div className="flex flex-col items-center justify-center flex-1">
        <Background type={2} />
        {action === "registration" && <Title>проверьте свой телефон</Title>}
        {action === "reset-password" && <Title>восстановление пароля</Title>}
        <div className="max-w-[500px] w-full px-4">
          <ConfirmPhoneForm />
        </div>
      </div>
    </ErrorBoundary>
  );
};
