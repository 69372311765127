import { isWeekend, isWorking } from "@/shared/lib";
import { TPlace } from "@/shared/model/types";
import { Card, ListGrid, Price, Rating, TypeBadge } from "@/shared/ui";
import { LockUnlock } from "./lock-unlock";
import React from "react";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsAuthorized } from "@/entities/session";
// TODO change import from features
import { ModalLogin } from "@/features/auth";
import { FavoriteButton } from "@/features/favorite-place";

interface IProps {
  FavoriteButton: typeof FavoriteButton;
  places: TPlace[];
  redirect_item_url?: string;
  isShowPrice?: boolean;
}

export const PlacesGrid: React.FC<IProps> = ({
  places,
  redirect_item_url = "/places/",
  FavoriteButton,
  isShowPrice = true,
}) => {
  const isAuth = useAppSelector(selectIsAuthorized);

  return (
    <div className="flex-1">
      <ListGrid>
        {places?.map((place) => {
          const isWorkingPlace = isWeekend()
            ? isWorking(
                place?.working_hours_weekends?.start,
                place?.working_hours_weekends?.end
              )
            : isWorking(place?.working_hours?.start, place?.working_hours?.end);

          const title = place?.short_title || place?.title;

          return (
            <Card
              key={place.id}
              className={isWorkingPlace ? "" : "opacity-70"}
              FavoriteButton={
                isAuth ? (
                  <FavoriteButton placeId={place.id} />
                ) : (
                  <ModalLogin>
                    <FavoriteButton placeId={place.id} />
                  </ModalLogin>
                )
              }
              TypeBadge={<TypeBadge>{place?.type?.title}</TypeBadge>}
              preview_image_url={
                place.preview_image.formats.small?.url ||
                place.preview_image.formats.thumbnail.url
              }
              redirectLink={redirect_item_url + place.id}
            >
              <h3 className="fb">{title}</h3>
              <div className="flex justify-between mt-2">
                <Rating id={place.id} rating={place.rating} />
                {isShowPrice && place?.average_price !== 0 && (
                  <Price isApproximately price={place.average_price} />
                )}
              </div>
              <div className="mt-2">
                <LockUnlock
                  working_hours={place?.working_hours}
                  working_hours_weekends={place?.working_hours_weekends}
                />
              </div>
            </Card>
          );
        })}
      </ListGrid>
    </div>
  );
};
