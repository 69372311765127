import { Loading } from "@/shared/ui";
import { useStaticArticles } from "../hooks/use-static-articles";
import { InformationBlock } from "@/entities/articles/ui/information-block";
import { TInformationBlock } from "@/shared/model/types";

interface IProps {
  className?: string;
}
export const UseConditionsArticle: React.FC<IProps> = ({ className }) => {
  const { useConditions, isLoading, isFetching } = useStaticArticles();

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  return (
    <div className={`${className}`}>
      <div className="flex flex-col gap-4 mt-4">
        {useConditions?.map((block: TInformationBlock) => (
          <div key={block.id}>
            <InformationBlock block={block} />
          </div>
        ))}
      </div>
    </div>
  );
};
