import { dayDeclension } from "@/shared/lib";
import { TImage, TTour } from "@/shared/model/types";
import { Collapse, ContactInfo, Hero } from "@/shared/ui";
import { ShareButton } from "@/shared/ui/share-button";

interface IProps {
  images: TImage[];
  tourData: TTour;
  children?: React.ReactNode;
  AdditionalDetails?: JSX.Element;
}

export const TourDetails: React.FC<IProps> = ({
  images,
  tourData,
  children,
  AdditionalDetails,
}) => {
  return (
    <>
      {images && (
        <Hero images={images}>
          <div className="flex h-full flex-col">
            <div className="flex items-center justify-between">
              <h3 className="text-xl fb">{tourData?.title}</h3>
              <div className="flex gap-2 ">
                <ShareButton size="sm" />
              </div>
            </div>
            <pre className="flex-grow overflow-y-auto basis-10 mt-2 hidden lg:block">
              {tourData?.description}
            </pre>
          </div>
        </Hero>
      )}
      <div className="block lg:hidden">
        {tourData?.description.length > 300 ? (
          <Collapse>
            <pre>{tourData?.description}</pre>
          </Collapse>
        ) : (
          <pre>{tourData?.description}</pre>
        )}
      </div>

      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-col w-full gap-2 mt-4 lg:mt-6 lg:w-1/2 ">
          <div className="w-full p-4 rounded-xl bg-base-200">
            <h4 className="text-xl fb">Расписание</h4>
            <ul className="space-y-2 list-disc">
              {tourData?.schedule_descriptions?.map((day) => (
                <li
                  key={day.id}
                  className="!collapse text-start collapse-arrow  "
                >
                  <input type="checkbox" />
                  <div className="text-base fb collapse-title">{day.title}</div>
                  <pre className="collapse-content">{day.description}</pre>
                </li>
              ))}
            </ul>
          </div>
          <ContactInfo
            phone={tourData?.organizational_phone}
            email={tourData?.email}
            site={tourData?.site}
            className="w-full "
          />
        </div>

        <div className="flex flex-col w-full gap-2 mt-4 lg:mt-6 lg:w-1/2">
          {children}
          <div className="p-4 rounded-xl bg-base-200">
            <h4 className="text-xl fb">Дополнительная информация</h4>
            <ul className="mt-2 space-y-2 list-disc ml-7">
              <li>
                Тур длится: {tourData?.schedule_descriptions?.length}{" "}
                {dayDeclension(tourData?.schedule_descriptions?.length)}
              </li>
              {AdditionalDetails}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
