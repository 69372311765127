import { Container, Details } from "@/shared/ui";
import { Link } from "react-router-dom";
import DropdownIcon from "@/shared/assets/icons/arrow-dropdown.svg?react";
import { PlacesMenu } from "./places-menu";

interface IProps {
  Account: React.ReactNode;
}

export const Header: React.FC<IProps> = ({ Account }) => {
  return (
    <header className="fixed z-40 navbar bg-base-300 lg:bg-base-200 hidden lg:block">
      <div className="flex justify-between w-full px-2 lg:hidden">
        <Link
          to={"/home#plates"}
          className="text-xl uppercase kalmyk-font text-primary"
        >
          ЭЛИСТА
        </Link>
        <div className="">{Account}</div>
      </div>
      <Container direction="row" className="hidden lg:flex">
        <div className="flex justify-start navbar-start">
          <Link
            to={"/home#plates"}
            className="text-xl uppercase kalmyk-font text-primary"
          >
            ЭЛИСТА
          </Link>
        </div>
        <nav className="text-black navbar-center">
          <ul className="px-1 uppercase menu menu-horizontal gap-1">
            <li>
              <Link to={"/articles"}>Новости</Link>
            </li>
            <li>
              <Link to={"/events"}>Афиша</Link>
            </li>
            <Details>
              <summary
                tabIndex={0}
                role="button"
                className="relative flex flex-col items-center justify-center w-full h-full gap-2 border-current cursor-pointer"
              >
                <span className="flex items-center px-2">
                  Места <DropdownIcon className="w-5" />
                </span>
              </summary>
              <PlacesMenu
                className={
                  "p-2 shadow menu dropdown-content z-[1] bg-base-200 rounded-box"
                }
              />
            </Details>
            <li>
              <Link to={"/places/list/oteli"}>Отели</Link>
            </li>
            <li>
              <Link to={"/tours"}>Туры</Link>
            </li>
            <li>
              <Link to={"/excursions"}>Экскурсии</Link>
            </li>

            <li>
              <Link to={"/my-guide"}>Мой гид</Link>
            </li>
          </ul>
        </nav>
        <div className="flex justify-end navbar-end">{Account}</div>
      </Container>
    </header>
  );
};
