import { Account } from "@/widgets/account";
import { MyGuideLayout } from "@/widgets/layouts/ui/my-guide-layout";
import { MapWithPlaces } from "@/widgets/map";
import { useAppSelector } from "@/shared/model/hooks";
import { selectIsAuthorized } from "@/entities/session";
import { Sidebar } from "./sidebar";
import { ErrorBoundary } from "@/widgets/layouts";
import { useWindowDimensions } from "@/shared/hooks";
import { MobileActionsBar } from "./mobile-actions-bar";

export const MyGuide: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuthorized);
  const { width: windowWith } = useWindowDimensions();
  const isDesktop = windowWith >= 1024;

  return (
    <MyGuideLayout Account={<Account />}>
      <ErrorBoundary>
        <div className="relative lg:items-start flex-1 flex  lg:flex-row flex-col">
          {isAuth && isDesktop && <Sidebar />}
          <div className="flex flex-grow">
            <MapWithPlaces />
          </div>
          {isAuth && !isDesktop && <MobileActionsBar />}
        </div>
      </ErrorBoundary>
    </MyGuideLayout>
  );
};
