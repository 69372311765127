/* eslint-disable react-hooks/exhaustive-deps */
import { usePayEventMutation } from "@/entities/events";
import { selectUserId } from "@/entities/user";
import { useAppSelector } from "@/shared/model/hooks";
import { THall, TSeat, TTicket } from "@/shared/model/types";
import { Loading } from "@/shared/ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IHall {
  hall: THall;
  price: number;
  tickets: TTicket[];
  scheduleId: number;
}

interface ISeats {
  seats: TSeat[];
  occupiedPlaces: number[];
  selectedSeats: number[];
  toggleSeat: (selectedSeat: number) => void;
}

type TTransformedSeats = {
  [key: number]: { id: number; seat_number: number }[];
};

const Seats: React.FC<ISeats> = ({
  seats,
  occupiedPlaces,
  selectedSeats,
  toggleSeat,
}) => {
  function transformSeats(seats: TSeat[]): TTransformedSeats {
    return seats.reduce((acc: TTransformedSeats, seat) => {
      acc[seat.row] = acc[seat.row] || [];
      acc[seat.row].push({ id: seat.id, seat_number: seat.seat_number });
      return acc;
    }, {});
  }

  const transformedSeats: TTransformedSeats = transformSeats(seats);
  const transformedSeatsKeys: number[] = Object.keys(transformedSeats).map(
    (row) => parseInt(row)
  );

  return (
    <div className="mt-5">
      {transformedSeatsKeys.map((row: number) => (
        <div key={"row-" + row} className="flex justify-center gap-2">
          {transformedSeats[row]
            .sort((a, b) => a.seat_number - b.seat_number)
            .map((seat) => {
              let isActive = false;
              if (selectedSeats && selectedSeats.includes(seat.id)) {
                isActive = true;
              }
              let isOccupied = false;
              if (occupiedPlaces.includes(seat.id)) {
                isOccupied = true;
              }

              return (
                <div
                  key={seat.id}
                  className="tooltip"
                  data-tip={`ряд - ${row}, место - ${seat.seat_number}`}
                >
                  <button
                    onClick={() => toggleSeat(seat.id)}
                    disabled={isOccupied}
                    className={`btn btn-sm aspect-square ${
                      isActive ? "btn-primary" : "btn-success"
                    }`}
                  />
                </div>
              );
            })}
        </div>
      ))}
    </div>
  );
};

export const Hall: React.FC<IHall> = ({ hall, price, tickets, scheduleId }) => {
  const [totalPrice, setTotalPrice] = useState<number>();
  const [selectedSeats, setSelectedSeats] = useState<number[]>();
  const [error, setError] = useState("");
  const userId = useAppSelector(selectUserId);
  const [paySeats, { isLoading }] = usePayEventMutation();
  const { id } = useParams();

  useEffect(() => {
    if (selectedSeats) {
      handleTotalPrice(selectedSeats.length);
    }
  }, [selectedSeats]);

  function toggleSeat(seatNumberId: number) {
    setSelectedSeats((prev) => {
      if (!prev) return [seatNumberId];
      if (prev.includes(seatNumberId)) {
        return prev.filter((id) => id !== seatNumberId);
      }
      if (prev.length === 5) return prev;
      return [...prev, seatNumberId];
    });
  }

  function handleTotalPrice(selectedSeatsNum: number) {
    setTotalPrice(selectedSeatsNum * price);
  }

  async function handlePaySeats() {
    if (totalPrice && id && selectedSeats && userId) {
      const redirectUrl = window.location.hostname + "/profile/tickets";
      try {
        await paySeats({
          event_id: Number(id),
          seats_ids: selectedSeats,
          schedule_id: scheduleId,
          user_id: userId,
          persons_count: selectedSeats.length,
          payment_payload: {
            payment_method_type: "bank_card",
            return_url: redirectUrl,
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
        if (error && typeof error === "string") setError(error);
      }
    }
  }

  const occupiedPlaces =
    tickets
      ?.filter((ticket) => ticket.schedule_id === scheduleId)
      .map((ticket) => ticket.seats)
      .flat()
      .map((seat) => seat.id)
      .flat() || [];

  return (
    <div className="flex flex-col gap-y-2">
      <h4 className="fb text-center">{hall.title}</h4>
      <div className="w-full px-3 mt-2 bg-gray-300 rounded">
        <p className="text-sm text-center">экран</p>
      </div>

      <Seats
        seats={hall.seats}
        occupiedPlaces={occupiedPlaces}
        selectedSeats={selectedSeats || []}
        toggleSeat={toggleSeat}
      />
      {error && <p className="text-error">{error}</p>}
      <div className="text-end">
        <button
          className="inline-block btn btn-primary"
          disabled={!totalPrice || isLoading}
          onClick={handlePaySeats}
        >
          {isLoading ? (
            <Loading size="sm" />
          ) : (
            <>
              {totalPrice ? (
                <>оплатить {totalPrice} руб</>
              ) : (
                <>места не выбраны</>
              )}
            </>
          )}
        </button>
      </div>
    </div>
  );
};
