import { baseApi } from "@/shared/api";
import { ISlide, ISlidesResponse } from "./../model/types";

export const homeSliderApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSlides: build.query({
      query: () => ({
        url: `/home-slides?populate=button&populate=backgroundImage`,
        method: "GET",
      }),
      transformResponse(res: ISlidesResponse): ISlide[] {
        return res.data.map((slide) => slide);
      },
    }),
  }),
});

export const { useGetSlidesQuery } = homeSliderApi;
