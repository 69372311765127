import { Container, Loading, Title } from "@/shared/ui";
import { Link } from "react-router-dom";
import { ArticlesGrid, useGetAllArticlesQuery } from "@/entities/articles";

interface IProps {
  className?: string;
}

export const ArticlesHome: React.FC<IProps> = ({ className }) => {
  const { articlesData, isLoading, isFetching } = useGetAllArticlesQuery(
    {
      populate: "populate=preview_image",
      page: 1,
      pageSize: 6,
      sort: "sort=createdAt:DESC",
    },
    {
      selectFromResult: (res) => {
        return {
          articlesData: res?.data?.data,
          ...res,
        };
      },
    }
  );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-30">
        <Loading size="lg" />
      </div>
    );

  if (articlesData?.length === 0) return <></>;

  return (
    <div className={className}>
      <Title>Новости</Title>
      {/* <HorizontalList redirectUrl="/articles/" items={articlesData} /> */}
      <Container direction="col" className="justify-end mt-4">
        <ArticlesGrid articles={articlesData} />
        <Link
          to={"/articles"}
          className="w-full text-base text-left uppercase sm:normal-case md:mr-2 md:w-auto btn btn-primary mt-2"
        >
          Показать все новости
        </Link>
      </Container>
    </div>
  );
};
