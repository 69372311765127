import { TExcursion, TImage } from "@/shared/model/types";
import { Collapse, ContactInfo, Hero } from "@/shared/ui";
import { ShareButton } from "@/shared/ui/share-button";

interface IProps {
  images: TImage[];
  excursionData: TExcursion;
  children?: React.ReactNode;
  AdditionalDetails?: JSX.Element;
}

export const ExcursionDetails: React.FC<IProps> = ({
  images,
  excursionData,
  children,
  AdditionalDetails,
}) => {
  return (
    <>
      {images && (
        <Hero images={images}>
          <div className="flex h-full flex-col">
            <div className="flex items-center justify-between">
              <h3 className="text-xl fb">{excursionData?.title}</h3>
              <div className="flex gap-2 ">
                <ShareButton size="sm" />
              </div>
            </div>
            <pre className="flex-grow overflow-y-auto basis-10 mt-2 hidden lg:block">
              {excursionData?.description}
            </pre>
          </div>
        </Hero>
      )}
      <div className="block lg:hidden">
        {excursionData?.description.length > 300 ? (
          <Collapse>
            <pre>{excursionData?.description}</pre>
          </Collapse>
        ) : (
          <pre>{excursionData?.description}</pre>
        )}
      </div>

      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-col w-full gap-2 mt-4 lg:mt-6 lg:w-1/2 ">
          <div className="w-full p-4 rounded-xl bg-base-200">
            <h4 className="text-xl fb">В цену входит</h4>
            <ul className="mt-2 space-y-2 list-disc ml-7">
              {excursionData?.description_list?.map((listItem) => (
                <li key={listItem.id}>
                  <pre>{listItem.content}</pre>
                </li>
              ))}
            </ul>
          </div>
          <ContactInfo
            phone={excursionData?.organizational_phone}
            email={excursionData?.email}
            site={excursionData?.site}
            className="w-full "
          />
        </div>

        <div className="flex flex-col w-full gap-2 mt-4 lg:mt-6 lg:w-1/2">
          {children}
          <div className="p-4 rounded-xl bg-base-200">
            <h4 className="text-xl fb">Дополнительная информация</h4>
            <ul className="mt-2 space-y-2 list-disc ml-7">
              <li>Длительность: {excursionData?.duration} ч.</li>
              {AdditionalDetails}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
