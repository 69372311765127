import { useParams } from "react-router-dom";
import { BreadCrumbs, Collapse, Loading, Price, WithIcon } from "@/shared/ui";
import { useGetGuideByIdQuery } from "../api/guides-api";
import PhoneIcon from "@/shared/assets/icons/phone.svg?react";
import EmailIcon from "@/shared/assets/icons/email.svg?react";
import { formatDate } from "@/shared/lib";
import { ShareButton } from "@/shared/ui/share-button";
import { TGuide } from "../model/types";

export const GuideDetails: React.FC = () => {
  const { id } = useParams();

  const { guideData, isLoading, isFetching } = useGetGuideByIdQuery(
    { id: Number(id) },
    {
      selectFromResult(res) {
        const guideData: TGuide = res?.data?.data;
        return { guideData, ...res };
      },
      skip: !id,
    }
  );

  if (isLoading || isFetching)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading size="lg" />
      </div>
    );

  const avatarUrl =
    guideData?.avatar?.formats?.medium?.url ||
    guideData?.avatar?.formats?.small?.url ||
    guideData?.avatar?.formats?.thumbnail?.url ||
    "https://waterpolo.ru/images/no-foto-avatar.png";

  return (
    <div className="flex flex-col items-center">
      <div className="max-w-[720px] w-full">
        <BreadCrumbs />
        {guideData && (
          <div className="">
            <div className="flex gap-4">
              <div className="avatar">
                <div className="w-40 rounded">
                  <img src={avatarUrl} className="bg-base-200" />
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <h3 className="text-xl fb">{guideData.name}</h3>
                <div className="mt-2">
                  <WithIcon Icon={<PhoneIcon className="w-6 text-primary" />}>
                    {guideData.phone && (
                      <a href={`tel:${guideData.phone}`}>{guideData.phone}</a>
                    )}
                  </WithIcon>
                  <WithIcon Icon={<EmailIcon className="w-6 text-primary" />}>
                    {guideData.email && (
                      <a href={`mailto:${guideData?.email}`}>
                        {guideData.email}
                      </a>
                    )}
                  </WithIcon>
                </div>
                <div className="mt-4">
                  <span className="hidden md:inline">Дата аккредитации:</span>{" "}
                  {formatDate(guideData.date_accreditation, true)}
                </div>
                <div className="flex gap-2 mt-2">
                  <span className="hidden md:inline">Цена за услуги:</span>{" "}
                  <Price className="" price={guideData.price} />
                </div>
              </div>
              <div className="absolute right-4 top-2 md:relative md:right-0 md:top-0">
                <ShareButton />
              </div>
            </div>

            <ul className="mt-4">
              {guideData?.links &&
                guideData?.links.map((link) => (
                  <li key={link.id}>
                    <a href={link.url} target="_blank" className="link">
                      {link.title}
                    </a>
                  </li>
                ))}
            </ul>

            <div className="mt-4 ">
              {guideData?.description && guideData?.description.length > 300 ? (
                <Collapse>
                  <pre>{guideData?.description}</pre>
                </Collapse>
              ) : (
                <pre>{guideData?.description}</pre>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
